import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { Form, Input, Button, message } from 'antd';
import { GatsbyImage } from 'gatsby-plugin-image';

// components
import Layout from '../components/layout';
import Seo from '../components/seo';
import Captcha from '../components/captcha';

// styled-component
const ContactSection = styled.section`
  padding: 1rem 10%;
  background-color: #eeeeee;

  @media only screen and (max-width: 468px) {
    padding: 1rem 6%;
  }
`;

const ContactTitle = styled.div`
  border-bottom: 1px outset #dddddd;
  color: #444444;
`;

const ContactTitleP = styled.p`
  font-size: 2em;
  margin-bottom: 0.5rem;
`;

const ContactWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: 1fr;
  gap: 1rem;
  margin: 1rem 0;
  padding: 1rem 0;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1px 0.7fr;
  }
`;

const ContactForm = styled.div`
  padding: 0 1rem 1rem 0;

  @media only screen and (max-width: 468px) {
    padding: 0;
  }
`;
const Separator = styled.div`
  border: 1px solid #dddddd;
  background-color: #dddddd;
`;
const ContactButton = styled(Button)`
  border: none;
  width: 100%;
  margin-top: 15px;
  font-weight: 700;
  text-transform: uppercase;
  color: #000;
  padding: 0em 1em;
  background-color: rgb(255, 215, 0);

  &:hover,
  &:focus {
    color: rgb(255, 215, 0);
    background: #000;
    border-color: 1px solid rgb(255, 215, 0);
  }
`;
const ContactLinkWA = styled.a`
  color: #000;
  &:hover {
    color: rgb(255, 215, 0);
  }
`;

const ContactLinkWASpan = styled.span`
  font-size: 1.2em;
`;

const PContactarnos = styled.p`
  text-align: justify;

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
`;

const Contact = React.memo(() => {
  const data = useStaticQuery(graphql`
    {
      sto: file(relativePath: { eq: "contact/contact-hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `);

  const [form] = Form.useForm();
  const [statusMessage, setStatus] = useState(false);
  const [reCaptchaStatus, setReCaptchaStatus] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);

  const onReset = () => {
    form.resetFields();
    setShouldReset(true);
    setStatus(false);
  };

  const onFinish = (values) => {
    if (reCaptchaStatus) {
      setStatus(true);
      setShouldReset(false);
      fetch('http://localhost:4000/send-email', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      })
        .then((res) => res.json())
        .then((d) => {
          if (d?.msg === 'success') {
            message.success('Tu mensaje fue enviado, pronto estaremos en contacto contigo.', 5);
          }
          if (d?.msg === 'fail') {
            message.error(
              'Oops parece que tenemos un problema, por favor comunicate con nosotros por medio de los números de contacto',
              5
            );
          }
        })
        .catch(() => {
          message.error(
            'Oops parece que tenemos un problema, por favor comunicate con nosotros por medio de los números de contacto',
            5
          );
        })
        .finally(() => {
          onReset();
        });
    } else {
      message.error('Por favor llena el reCAPTCHA');
    }
  };

  return (
    <Layout>
      <Seo title="Contacto" />
      <ContactSection>
        <ContactTitle>
          <ContactTitleP>Contactanos</ContactTitleP>
        </ContactTitle>
        <ContactWrapper>
          <ContactForm>
            <Form
              layout="vertical"
              method="POST"
              form={form}
              onFinish={onFinish}
              scrollToFirstError
            >
              <Form.Item
                name="name"
                label="Nombre"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastname"
                label="Apellido"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'Este campo no es valido!',
                  },
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Celular"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="company" label="Compañia">
                <Input />
              </Form.Item>
              <Form.Item
                name="city"
                label="Ciudad"
                rules={[
                  {
                    required: true,
                    message: 'Por favor, completa este campo, es requerido!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="comments" label="Comentarios o Preguntas">
                <Input.TextArea style={{ height: '220px' }} />
              </Form.Item>
              <Captcha setStatus={setReCaptchaStatus} shouldReset={shouldReset} />
              <ContactButton
                type="primary"
                htmlType="submit"
                disabled={statusMessage}
                loading={statusMessage}
              >
                Enviar
              </ContactButton>
            </Form>
          </ContactForm>
          <Separator />
          <div
            style={{
              textAlign: 'center',
              fontSize: '16px',
              paddingLeft: '1em',
            }}
          >
            <PContactarnos
              style={{
                marginBottom: '0',
                textTransform: 'uppercase',
                color: '#000',
              }}
            >
              <b>
                <span>Sientete libre de contactarnos</span>
              </b>
            </PContactarnos>
            <PContactarnos
              style={{
                fontSize: '14px',
                color: '#000',
              }}
            >
              <b>
                <span>Lunes - Viernes | 7:30 am - 5:30 pm</span>
              </b>
            </PContactarnos>
            <PContactarnos>
              Para nosotros es muy importante estar en contacto contigo, por eso puedes comunicarte
              con nosotros en horario laboral con los siguientes numeros.
            </PContactarnos>
            <div style={{ marginBottom: '0.5em' }}>
              <span style={{ fontWeight: '600' }}>Oficina Sede Principal:</span>
              <span> (031) 745 12 80</span>
            </div>
            <div style={{ marginBottom: '0.5em' }}>
              <span style={{ fontWeight: '600' }}>Representante de Ventas Nacional:</span>
              <ContactLinkWA
                href="https://wa.me/3123777848?text=Hola,%20estoy%20interesado%20en%20los%20productos%20Sto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkWASpan style={{ fontSize: '1rem' }}> 312 377 78 48</ContactLinkWASpan>
              </ContactLinkWA>
            </div>
            <div style={{ marginBottom: '0.5em' }}>
              <span style={{ fontWeight: '600' }}>Representante de Ventas Nacional 2:</span>
              <ContactLinkWA
                href="https://wa.me/3102709584?text=Hola,%20estoy%20interesado%20en%20los%20productos%20Sto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkWASpan style={{ fontSize: '1rem' }}> 310 270 95 84</ContactLinkWASpan>
              </ContactLinkWA>
            </div>
            <div style={{ marginBottom: '0.5em' }}>
              <span style={{ fontWeight: '600' }}>Cundinamarca:</span>
              <ContactLinkWA
                href="https://wa.me/3202468653?text=Hola,%20estoy%20interesado%20en%20los%20productos%20Sto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkWASpan style={{ fontSize: '1rem' }}> 310 246 86 53</ContactLinkWASpan>
              </ContactLinkWA>
            </div>
            <div style={{ marginBottom: '0.5em' }}>
              <span style={{ fontWeight: '600' }}>Antioquia:</span>
              <ContactLinkWA
                href="https://wa.me/3014488956?text=Hola,%20estoy%20interesado%20en%20los%20productos%20Sto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkWASpan style={{ fontSize: '1rem' }}> 301 448 89 56</ContactLinkWASpan>
              </ContactLinkWA>
            </div>
            <div style={{ marginBottom: '0.5em' }}>
              <span style={{ fontWeight: '600' }}>Valle:</span>
              <ContactLinkWA
                href="https://wa.me/3226777488?text=Hola,%20estoy%20interesado%20en%20los%20productos%20Sto"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLinkWASpan style={{ fontSize: '1rem' }}> 322 677 74 88</ContactLinkWASpan>
              </ContactLinkWA>
            </div>
            <div style={{ margin: '2rem auto' }}>
              <GatsbyImage
                image={data.sto.childImageSharp.gatsbyImageData}
                style={{ marginTop: '16px' }}
                alt="logo-sto"
              />
            </div>
          </div>
        </ContactWrapper>
      </ContactSection>
    </Layout>
  );
});

export default Contact;
